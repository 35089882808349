<template>
    <div class="amap-page-container" style="height: 400px; margin-bottom: 200px; background: #ccc">
        <!-- <el-amap-search-box class="search-box" :search-option="searchOption" :on-search-result="onSearchResult"></el-amap-search-box> -->
        <el-amap vid="amapDemo" :center="center" :zoom="zoom" class="amap-demo" :plugin="plugin" :events="events">
            <!-- <el-amap-marker v-for="(marker, index) in markers" :position="marker" :key="'marker' + index"></el-amap-marker> -->
            <el-amap-marker :position="center" > </el-amap-marker>
        </el-amap>
        <div class="toolbar" >
            position: [{{ lng }}, {{ lat }}] address: {{ address }}
        </div>
        <!-- <div v-else>正在定位</div> -->
        <div class="btn" >
            <van-button round block type="info" @click="comfirmFun">确认</van-button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        longitude: {
            type: String
        },
        latitude: {
            type: String
        }
    },
    created() {
        // console.log("lng", this.lng)
        // console.log("lat", this.lat)
        this.lng = this.longitude
        this.lat = this.latitude
        this.center = [ this.lng, this.lat];
    },
    data() {
        let self = this;

        return {
            // markers: [
            //     [121.59996, 31.197646],
            // ],
            searchOption: {
                city: "杭州",
                citylimit: false,
            },
            zoom: 12,
            loaded: false,
            center: [121.59996, 31.197646],
            plugin: [
                // {
                //     pName: "Geolocation",
                //     events: {
                //         init(o) {
                //             // o 是高德地图定位插件实例
                //             o.getCurrentPosition((status, result) => {
                //                 if (result && result.position) {
                //                     self.lng = result.position.lng;
                //                     self.lat = result.position.lat;
                //                     self.center = [self.lng, self.lat];
                //                     self.address = result.formattedAddress;
                //                     self.loaded = true;
                //                     self.$nextTick();
                //                 }
                //             });
                //         },
                //     },
                // },
            ],
            address: "",
            events: {
                click(e) {
                    console.log("E",e)
                    self.lng =  e.lnglat.lng
                    self.lat =  e.lnglat.lat
                    self.center = [e.lnglat.lng, e.lnglat.lat]
                    // 这里通过高德 SDK 完成。
                    var geocoder = new AMap.Geocoder({
                        radius: 1000,
                        extensions: "all",
                    });
                    geocoder.getAddress([e.lnglat.lng,  e.lnglat.lat], function (status, result) {
                        if (status === "complete" && result.info === "OK") {
                            if (result && result.regeocode) {
                                self.address = result.regeocode.formattedAddress;
                                self.$nextTick();
                            }
                        }
                    });
                },
            },
        };
    },
    methods: {
        addMarker: function () {
            let lng = 121.5 + Math.round(Math.random() * 1000) / 10000;
            let lat = 31.197646 + Math.round(Math.random() * 500) / 10000;
            this.markers.push([lng, lat]);
        },
        onSearchResult(pois) {
            console.log('pois',pois)
            let latSum = 0;
            let lngSum = 0;
            if (pois.length > 0) {
                pois.forEach((poi) => {
                    let { lng, lat } = poi;
                    lngSum += lng;
                    latSum += lat;
                    this.markers.push([poi.lng, poi.lat]);
                });
                let mapcenter = {
                    lng: lngSum / pois.length,
                    lat: latSum / pois.length,
                };
                this.center = [mapcenter.lng, mapcenter.lat];
            }
        },
        comfirmFun() {
            this.$emit("getLngLats", [this.lng.toString(), this.lat.toString()]);
        }
    },
    watch: {
        address: {
            handler: function (val, oldVal) {
                this.address = val;
                this.$emit("sendAddress", this.address);
            },
            // 深度观察监听
            deep: true,
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.search-box {
  position: absolute;
  top: 25px;
  left: 20px;
}

.amap-page-container {
  position: relative;
}
.btn {
  width: 90%;
  margin: 10px auto 0;
}
</style>
