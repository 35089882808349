<template>
  <div class="page">
    <!-- <top-bar :title="'我要求助'"></top-bar> -->
    <!-- <div class="line"></div> -->
    <div class="content">
      <div class="tit">求助详情</div>
      <div class="result">
        <van-form @submit="onSubmit" input-align="right" error-message-align="right" ref='dataForm'>
          <van-field required readonly clickable name="helpType" :value="formData.helpType" label="求助类型" placeholder="请选择" @click="showPickerFun('helpType')" :rules="[{ required: true, message: '请选择求助类型',trigger:'onChange' }]" />
          <van-field required readonly clickable name="placeType" :value="formData.placeType" label="地点类型" placeholder="请选择" @click="showPickerFun('placeType')" />
          <van-field required readonly clickable name="area" :value="formData.area" label="所在小区" placeholder="请选择" @click="showPickerFun('area')" :rules="[{ required: true, message: '请选择所在小区',trigger:'onChange' }]" />
          <template v-if="formData.placeType == '公共区域'">
            <van-field required name="specificPlace" v-model="formData.specificPlace" rows="1" autosize label="具体地址" type="textarea" placeholder="请输入" :rules="[{ required: true, message: '请填写具体位置' }]" />
            <van-field required v-model="formData.location" label="定位" placeholder="" right-icon="location" readonly @click="mapShow = true" :rules="[{ required: true, message: '请定位' ,trigger:'onChange'}]" />
          </template>
          <template v-else>
            <van-field readonly clickable :value="formData.building" label="所在楼幢" placeholder="请选择" @click="showPickerFun('building')" v-if="buildingShow" />
            <van-field readonly clickable :value="formData.unit" label="所在单元" placeholder="请选择" @click="showPickerFun('unit')" v-if="unitShow" />
            <van-field readonly clickable :value="formData.room" label="所在房屋" placeholder="请选择" @click="showPickerFun('room')" v-if="roomShow" />
          </template>
          <van-field required name="salvationDesc" v-model="formData.salvationDesc" rows="2" autosize label="求助描述" type="textarea" placeholder="请输入" :rules="[{ required: true, message: '请填写求助描述' }]" />
          <van-popup v-model="showPicker" position="bottom">
            <van-picker show-toolbar :columns="helpTypeColumns" @confirm="popupConfirm" @cancel="showPicker = false" v-if="type == 'helpType'" />
            <van-picker show-toolbar :columns="placeTypeColumns" @confirm="popupConfirm" @cancel="showPicker = false" v-if="type == 'placeType'" />
            <van-picker show-toolbar :columns="areaColumns" @confirm="popupConfirm" @cancel="showPicker = false" v-if="type == 'area'" />
            <van-picker show-toolbar :columns="buildingColumns" @confirm="popupConfirm" @cancel="showPicker = false" v-if="type == 'building'" />
            <van-picker show-toolbar :columns="unitColumns" @confirm="popupConfirm" @cancel="showPicker = false" v-if="type == 'unit'" />
            <van-picker show-toolbar :columns="roomColumns" @confirm="popupConfirm" @cancel="showPicker = false" v-if="type == 'room'" />
          </van-popup>
          <div class="btn" v-if="!showPicker &&!mapShow">
            <van-button round block type="info" native-type="submit">确定上传</van-button>
          </div>
        </van-form>
        <van-popup v-model="mapShow" closeable position="bottom" :style="{ height: '90%' }">
          <MapComponent @getLngLats="getLngLat" :longitude ="lng" :latitude ="lat"></MapComponent>
        </van-popup>
        <div class="tit">上传图片</div>
        <div class="img">
          <van-uploader v-model="fileList" :multiple="false" @delete="uploaddel" :after-read="uploadRead" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import topBar from "@/components/topBar/topBar";
import MapComponent from "@/components/MapComponent/map";
import { Toast, Dialog } from "vant";
// import { upload, getDictTree } from '@/utils/common'
import { mapState } from "vuex";
import { uploadImg } from '@/utils/common'
export default {
  data() {
    return {
      buildingShow: true,
      unitShow: false,
      roomShow: false,
      dir: "files/jq/salvation/temporary",
      mapShow: false,
      showPicker: false,
      type: "",
      value: 5,
      lat: "",
      lng: "",
      helpTypeColumns: [],
      placeTypeColumns: [
        { text: "公共区域", value: 1 },
        { text: "楼内楼幢", value: 2 }
      ],
      areaColumns: [],
      buildingColumns: [],
      unitColumns: [],
      roomColumns: [],
      fileList: [],
      formData: {
        helpType: "",
        placeType: "公共区域",
        area: '',
        building: '',
        unit: '',
        room: '',
        salvationDesc: '',
        specificPlace: '',
        location: ''
      },
      newFileMessages: [],
    };
  },
  created() {
    this.lat = this.$location.latitude
    this.lng = this.$location.longitude
    this.formData.location = "(" + this.$location.longitude + "," +this.$location.latitude + ")";
    this.getListDictByCode("salvationType");
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.getDataList();
    } else {
      this.subAreaList();
    }
    console.log("this.$route.query", this.$route.query)
  },
  beforeDestroy() { },
  methods: {
    getDataList() {
      this.$httpApp({
        url: this.$httpApp.adornUrl(`/wxapp/app/appjqsalvation/infoCheck/${this.id}`),
        method: "get",
        params: this.$httpApp.adornParams()
      }).then(({ data }) => {
        if (data.code == 0) {
          console.log(data);
          this.formData.area = data.appJqSalvation.subarea;
          this.formData.building = data.appJqSalvation.buildingId;
          this.formData.unit = data.appJqSalvation.unitId;
          this.formData.room = data.appJqSalvation.houseId;
          this.formData.helpType = data.appJqSalvation.helpType
          this.formData.placeType = data.appJqSalvation.placeType
          this.formData.helpType = this.getText("helpType")
          this.formData.placeType = this.getText("placeType")
          this.formData.specificPlace = data.appJqSalvation.specificPlace;
          this.formData.salvationDesc = data.appJqSalvation.salvationDesc;
          this.formData.location = "(" + data.appJqSalvation.lng + "," + data.appJqSalvation.lat + ")";
          this.lng = data.appJqSalvation.lng
          this.lat = data.appJqSalvation.lat
          this.appJqSalvation = data.appJqSalvation;
          if (data.appJqSalvation.fileMessages) {
            data.appJqSalvation.fileMessages.map((item) => {
              this.fileList.push({ url: item.objectUrl, id: item.id, fileType: item.fileType });
            });
          }
          this.subAreaList();
          this.getBySubArea(data.appJqSalvation.subarea);
        }
      });
    },
    //获取所有小区
    subAreaList(code) {
      this.$http({
        url: this.$http.adornUrl("/wxapp/sys/org/subAreaList"),
        method: "get",
        params: this.$http.adornParams({
          communityId: this.$orgId
        })
      }).then(({ data }) => {
        if (data.code == 0) {
          console.log(data);
          data.subAreaList.map(item => {
            this.areaColumns.push({ text: item.name, value: item.id, ...item });
          });
          if (this.id) {
            this.formData.area = this.getText("area");
          }
        } else {
          Toast.fail(data.msg);
        }
      });
    },
    //获取楼幢
    getBySubArea(id) {
      console.log("id", id);
      this.$http({
        url: this.$http.adornUrl("/wxapp/building/info/getBySubArea"),
        method: "get",
        params: this.$http.adornParams({
          subArea: id
        })
      }).then(({ data }) => {
        if (data.code == 0) {
          console.log(data);
          data.buildings.map(item => {
            this.buildingColumns.push({
              text: item.label,
              ...item
            });
          });
          if (this.id) {
            this.formData.building = this.getText("building");
            let bId = this.getValue('building')
            let fliter = this.buildingColumns.filter(item => { return (item.value == bId) })
            this.formData.divideHouse = fliter.length > 0 ? fliter[0].divideHouse : ''
            if (this.appJqSalvation.houseId != '') this.listByBuildingId(bId)
          }
        } else {
          Toast.fail(data.msg);
        }
      });
    },
    //获取所有单元
    listByBuildingId(id) {
      console.log("id", id);
      this.$http({
        url: this.$http.adornUrl("/wxapp/building/unit/listByBuildingId"),
        method: "get",
        params: this.$http.adornParams({
          buildingId: id
        })
      }).then(({ data }) => {
        if (data.code == 0) {
          console.log('datadata', data);
          if (data.units.length > 0) {
            this.unitShow = true;
            data.units.map(item => {
              this.unitColumns.push({ text: item.label, ...item });
            });
            this.formData.unit = this.getText('unit')
            this.listByUpId(this.getValue("unit"))
          } else this.listByUpId(this.getValue("building"))
        } else {
          Toast.fail(data.msg);
        }
      });
    },
    //获取所有房屋
    listByUpId(id) {
      console.log("id", id);
      // console.log("divideHouse", this.formData);
      this.$http({
        url: this.$http.adornUrl("/wxapp/building/house/listByUpId"),
        method: "get",
        params: this.$http.adornParams({
          upId: id,
          bOru: this.formData.divideHouse
        })
      }).then(({ data }) => {
        if (data.code == 0) {
          if (data.houseOptions.length > 0) {
            this.roomShow = true;
            data.houseOptions.map(item => {
              this.roomColumns.push({ text: item.label, value: item.value, ...item });
            });
          }
          this.formData.room = this.getText("room");
        } else {
          Toast.fail(data.msg);
        }
      });
    },
    getListDictByCode(code) {
      this.$http({
        url: this.$http.adornUrl(`/wxapp/sys/dict/listDictByCode`),
        method: "get",
        params: this.$http.adornParams({ code })
      }).then(({ data }) => {
        if (data.code == 0) {
          data.dicts.map(item => {
            this.helpTypeColumns.push({ text: item.label, value: item.value });
          });
        } else {
          Toast.fail(data.msg);
        }
      });
    },
    showPickerFun(type) {
      // console.log(type)
      this.type = type;
      this.showPicker = true;
    },
    popupConfirm(e) {
      console.log("eeeeee", e)
      let type = this.type;
      if (type == "helpType") {
        this.formData.helpType = e.text;
      }
      if (type == "placeType") {
        this.$refs['dataForm'].resetValidation()
        this.formData.placeType = e.text;
      }
      if (type == "area") {
        this.buildingColumns = [];
        this.unitColumns = [];
        this.roomColumns = [];
        this.formData.building = "";
        this.formData.divideHouse = "";
        this.formData.unit = "";
        this.formData.room = "";
        this.formData.area = e.text;
        this.getBySubArea(e.value);
      }
      if (type == "building") {
        if (e.divideHouse != 0) {
          this.listByBuildingId(e.value);
        }
        this.unitColumns = [];
        this.roomColumns = [];
        this.formData.unit = "";
        this.formData.room = "";
        this.formData.building = e.text;
        this.formData.buildingId = e.value;
        this.formData.divideHouse = e.divideHouse;
      }
      if (type == "unit") {
        this.roomColumns = [];
        this.formData.room = "";
        this.formData.unit = e.text;
        this.listByUpId(e.value);
      }
      if (type == "room") {
        this.formData.room = e.text;
      }
      this.showPicker = false;
    },
    getLngLat(val) {
      console.log("val",val)
      // console.log("val",val)
      this.lng = val[0];
      this.lat = val[1];
      this.formData.location = "(" + val[0] + "," + val[1] + ")";
      this.mapShow = false;
    },
    // 删除图片
    uploaddel(file, detail) {
      let i = detail.index
      this.newFileMessages.splice(i, 1)
    },
    // 上传图片
    uploadRead(file) {
      this.$toast.loading({
        message: '上传中...',
        forbidClick: true,
        duration: 0,
        overlay: true
      });
      uploadImg(file.file, this.dir, (data) => {
        this.$toast.clear()
        // console.log("data",data)
        if (data.fileMessage) {
          this.newFileMessages.push(data.fileMessage)
        } else {
          this.$toast.console.error({ message: '上传失败' })
        }
      })
    },
    onSubmit(val) {
      console.log("val", val)
      // console.log("onSubmit", this.formData)
      let obj = {};
      console.log("getValue", this.getValue('area'))
      if (this.formData.placeType == '公共区域') {
        obj = {
          userId: this.$userId,
          helpType: this.getValue('helpType'),
          placeType: this.getValue('placeType'),
          salvationDesc: val.salvationDesc,
          specificPlace: val.specificPlace,
          lat: this.lat,
          lng: this.lng,
          subarea: this.getValue('area'),
          newFileMessages: this.newFileMessages,
        };
      } else {
        obj = {
          userId: this.$userId,
          helpType: this.getValue('helpType'),
          placeType: this.getValue('placeType'),
          salvationDesc: val.salvationDesc,
          subarea: this.getValue('area'),
          buildingId: this.getValue('building'),
          unitId: this.getValue('unit'),
          houseId: this.getValue('room'),
          newFileMessages: this.newFileMessages,
        };
      }
      console.log("obj", obj)
      this.$httpApp({
        url: this.$httpApp.adornUrl(`/wxapp/app/appjqsalvation/save`),
        method: "post",
        data: this.$httpApp.adornData({
          id: this.id,
          ...obj
        })
      }).then(({ data }) => {
        console.log(data);
        if (data && data.code === 0) {
          Toast.success("操作成功");
          setTimeout(() => {
            this.$router.push({ name: 'rescue-list' })
          }, 500)
        } else {
          Toast.fail(data.msg);
        }
      });
    },
    getValue(key) {
      let filter = ''
      filter = this[key + 'Columns'].filter(item => { return (item.text == this.formData[key]) })
      return filter.length <= 0 ? '' : filter[0].value
    },
    getText(key) {
      let filter = ''
      filter = this[key + 'Columns'].filter(item => { return (item.value == this.formData[key]) })
      return filter.length <= 0 ? '' : filter[0].text
    },
  },

  components: {
    topBar,
    MapComponent
  }
};
</script>
<style  lang="scss" scoped>
.page {
  padding: 0 0 120px 0;
  min-height: 100%;
  background: #fff;
  box-sizing: border-box;
  .content {
    font-size: 24px;
    .tit {
      padding: 30px 0 30px 40px;
      position: relative;
      color: #000;
      font-weight: bold;
      font-size: 26px;
      //   border-bottom: 1px solid #eee;
      &::before {
        content: "";
        width: 4px;
        height: 26px;
        background: #017afd;
        position: absolute;
        left: 30px;
        top: 30px;
      }
    }
    .img {
      padding: 20px 30px;
    }
  }
}
.line {
  height: 20px;
  width: 100%;
  background: #f5f5f5;
}
.btn {
  width: 90%;
  position: fixed;
  bottom: 0;
  left: 5%;
  height: 100px;
  z-index: 9999;
}
</style>
